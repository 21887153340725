import React from 'react';
import styles from './styles.module.css';
import { Button } from 'react-bootstrap';
import dataResources from '../../../../../constants/dataResources';
import Select from 'react-select';
import Loader from 'react-loader-spinner';

function UpdateOrderStatus({ orderId, statusToUpdate, setStatusToUpdate, reasonOfUpdate, setReasonOfUpdate, loader, updateOrderStatus }) {
  const orderStatusList = dataResources.orderStatusList.map(status=>{return {label: status, value: status};})
  return (
    <div>
      <div className="page-heading secondary">Update Order Status</div>
      <div className={styles["row"]}>
        <div className={styles["field"]}>ORDER ID</div>
        <div className={styles["value"]}>{orderId}</div>
      </div>
      <hr/>
      <div className={styles["field"]}>Select Order Status</div>
      <div className={styles["row"]}>
        <Select className="regular-select" value={{label: statusToUpdate, value: statusToUpdate}} options={orderStatusList} style={{width: '200px'}} onChange={status=>setStatusToUpdate(status.value)} />
      </div>
      <div className={styles["field"]} style={{marginTop: '20px'}}>Reason of update <span style={{color: "rgb(200,0,0)", fontSize: "10px"}} hidden={reasonOfUpdate}>*Required</span></div>
      <div className={styles["row"]}>
        <input className="form-control" placeholder="Enter Reason" value={reasonOfUpdate} onChange={evt=>setReasonOfUpdate(evt.target.value)} />
      </div>
      {
        loader?
        <Loader type="ThreeDots" width={30} height={30} color="#144166" />
        :
        null
      }
      <div className={styles["row"]}>
        <Button
          variant="dark"
          style={{ marginTop: "20px", width: "100%"}}
          onClick={updateOrderStatus}
          disabled = {!statusToUpdate || !reasonOfUpdate || loader}
        >
          Update
        </Button>
      </div>
    </div>
  )
}
export default UpdateOrderStatus;