export const getBrand = () => {
  const webUrl = window.location.hostname;
  let brand = "MM";
  if(webUrl.indexOf("bebodywise")>=0) {
    brand = "BW";
  }
  if(webUrl.indexOf("manmatters")>=0) {
    brand = "MM";
  }
  if(webUrl.indexOf("ourlittlejoys")>=0) {
    brand = "LJ";
  }
  return brand;
}

export const getParsedValue = (url, parameters) => {
  let mainIndex = 0;
  const len = url.length; 
  const keys = [];
  while(mainIndex<len) {
    let startPoint= url.indexOf("{{", mainIndex);
    if(startPoint === -1) break;
    startPoint+=2;
    const endPoint= url.indexOf("}}",startPoint);
    if(endPoint === -1) break;
    const value = url.slice(startPoint, endPoint);
    keys.push(value);
    mainIndex=endPoint+2;
  }
  let parsedUrl = url;
  for(const key of keys) {
    const value = parameters[key] || "";
    parsedUrl = parsedUrl.replace(`{{${key}}}`, value);
  }
  return parsedUrl;
}

export const getPaddedValue = (value) => {
  value = value + "";
  const paddedValue = value.padStart(9, "0");
  return paddedValue;
}
export const getUnpaddedValue = (value) => {
  value = value + "";
  const unpaddedValue = parseInt(value) + "";
  return unpaddedValue;
}

export const getParsedOrderId = (orderId) => {
  const brand = getBrand();
  if(brand === "MM") return orderId;
  if(brand === "BW") return getPaddedValue(orderId);
  if(brand === "LJ") return getPaddedValue(orderId);
}