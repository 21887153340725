import React, { useState, useEffect } from 'react';
import SimpleTable from '../../../../common/Tables/SimpleTable/SimpleTable';
import styles from './styles.module.css';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import dataResources from '../../../../../constants/dataResources';
import OrderReceipt from './OrderReceipt';
import RefundModal from './refundModal';
import axios from 'axios';
import { URL_LIST } from '../../../../../constants/apiList';
import Loader from 'react-loader-spinner';
import { toast } from 'react-toastify';

function OrderPrescription({ orderDetails, setShowModal, setModalContentType, cancelConfirmModal, cancelButtonText}) {
  const [basicDetails, setBasicDetails] = useState([]);
  const [loader, setLoader] = useState(false);
  const [retryUnicommerceFlag, setRetryUnicommerceFlag] = useState(dataResources?.retryUnicommerceCode?.includes?.(Number(orderDetails?.unicommerce_status)));
  const [showRefundOptions, setShowRefundOptions] = useState(false);
  useEffect(()=>{
    if(orderDetails && orderDetails.order_date) {
      prepareBasicDetails();
    }
  },[orderDetails]);
  const prepareAddress = (address) => {
    let addressString = '';
    if(address.address_1) addressString += `${address.address_1}, `;
    if(address.address_2) addressString += `${address.address_2}, `;
    if(address.city) addressString += `${address.city}, `;
    if(address.state) addressString += `${address.state}, `;
    if(address.postcode) addressString += `${address.postcode}`;
    return addressString;
  }

  const prepareBasicDetails = () => {
    const expectedDeliveryDate = orderDetails?.expected_delivery_date || "-";
    const basicDetailsFinal = [
      { title: "Date", value: moment(orderDetails.order_date.date).format("DD MMM YYYY") },
      { title: "Expected Delivery Date", value: expectedDeliveryDate },
      { title: "Order ID", value: orderDetails.order_id },
      { title: "Payment Mode", value: orderDetails.payment_method },
      { title: "Payment Id", value: orderDetails.payment_id },
      { title: "Amount", value: orderDetails.order_total },
      { title: "Order Status", value: orderDetails.order_status },
      { title: "Appointment", value: orderDetails.appointment },
      { title: "Doctor Assigned", value: orderDetails.doctor_assigned },
      { title: "CP Status", value: orderDetails.cp_status },
      { title: "Clickpost Status Code", value: dataResources.clickpostStatusList[orderDetails.clickpost_status_code] },
      { title: "Shipping Address", value: prepareAddress(orderDetails.shipping_address) },
      { title: "Delivery partner", value: orderDetails.delivery_partner },
      { title: "AWB", value: orderDetails.awb },
      { title: "Platform", value: orderDetails.platform },
    ];
    setBasicDetails(basicDetailsFinal);
  }
  if(!(orderDetails && orderDetails.order_date)) return <>NO DATA FOUND !!!</>;
  const renderBasicDetails = () => {
    const defaultValue = "N/A";
    if(!(basicDetails && basicDetails.length)) return <></>
    return basicDetails.map((row,index)=>{
      return (
        <div className={styles["row"]} key={index}>
          <div className={styles["field"]}>{row.title}</div>
          <div className={styles["value"]}>{row.value || defaultValue}</div>
        </div>
      )
    })
  }

  const clickPostStatusCheck = orderDetails?.clickpost_status_code && (orderDetails.clickpost_status_code === 11 || orderDetails.clickpost_status_code === 12 || orderDetails.clickpost_status_code === 13 || orderDetails.clickpost_status_code === 14);
  const isCancelApplicable = !orderDetails?.cancel_history && orderDetails.order_status && orderDetails.order_status !== "canceled" && orderDetails.order_status !== "rx_edited" && orderDetails.order_status !== "pending" && orderDetails.order_status !== "completed" && orderDetails.order_status !== "failed" && (((orderDetails.order_status === "shipped" || orderDetails.order_status === "in-transit") &&  clickPostStatusCheck) || orderDetails.order_status !== "shipped");
  const pushToUniware = async () => {
    setLoader(true);
    const requestData = {
        order_id : orderDetails.order_id
    }
    axios.post(URL_LIST.RETRY_UNICOMMERCE, requestData).then((res)=>{
        setRetryUnicommerceFlag(false) 
        setLoader(false);
        toast.info("Order Pushed to Uniware")
    }).catch((e)=>{
        setLoader(false);
        toast.info("Failed to Push Order to Uniware, Try Again!")
    })
  }
  return loader ? (<Loader className={styles['loader']} type='TailSpin' width={200} height={200} color="#144166"/> ) : (
    <>
    <div>
      <div>
        <div className="page-heading secondary">Order Details</div>
        {
          renderBasicDetails()
        }
        <div style={{marginTop: "10px"}}>
          <div className="order-options-buttons" style={{textAlign:"center"}}>
            <a href={orderDetails.tracking_url} target="_blank" style={{textDecoration: "none"}}>
              <Button variant="success" size="sm" style={{width: "30%", margin: "10px 0px"}} disabled={!orderDetails.tracking_url}>
                Track Order
              </Button>
            </a>
            {orderDetails.refundDetails.eligible && <Button onClick={()=>{setShowRefundOptions(!showRefundOptions)}} variant="danger" size="sm" style={{width: "30%", margin: "10px 10px"}} >
              Process Refund
            </Button>}
            {retryUnicommerceFlag && <Button onClick={pushToUniware} variant="info" size="sm" style={{width: "30%", margin: "10px 10px"}} >
              Push To Uniware
            </Button>}
          </div>
          {showRefundOptions && <RefundModal orderDetails={orderDetails}/>}
          <SimpleTable rows={orderDetails.tableRows} columns={orderDetails.tableColumns}/>
          <OrderReceipt orderDetails={orderDetails} />
        </div>
      </div>
    </div>
    <div className={styles["row"]}>
      {/* {
        isCancelApplicable ? 
          <Button
          className="regular-button"
          style={{ marginTop: "20px", width: "100%"}}
          onClick={()=>setModalContentType(cancelConfirmModal)}
        >
          {cancelButtonText}
        </Button>
        :null
      } */}
    </div>
    </>
  )
}
export default OrderPrescription;