import React, {useState} from 'react';
import styles from './CreateAccount.module.css';
import { Dropdown, Button } from 'react-bootstrap';
import dataResources from '../../../constants/dataResources';
import { URL_LIST } from '../../../constants/apiList';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import {toast, ToastContainer} from 'react-toastify';

function CreateAccount(props) {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [validPhone, setValidPhone] = useState(false);
  const [role, setRole] = useState(dataResources.roles[0]);
  const [active, setActive] = useState(1);
  const [loader, setLoader] = useState(false);
  const createAccount = async () => {
    setLoader(true);
    const requestData = {
      name,
      role: role.value,
      active,
      phone
    };
    await axios.post(URL_LIST.CREATE_ACCOUNT, requestData)
    .then(res=>{
      console.log("res",res);
      if(res.data.success) {
        toast.success(res.data.message);
        setName("");
        setPhone("");
        setValidPhone(false)
      }
      else {
        toast.error(res.data.message);
      }
      setLoader(false);
    })
    .catch(err=>{
      setLoader(false);
      toast.error("Unable to make create Account Call");
      console.log("ERROR IN /createAccount", err);
    })
  }
  const handlePhoneInput = (phoneNo)=>{
    if(phoneNo.length <= 10){
      setPhone(phoneNo)
      if(phoneNo?.length === 10){
        setValidPhone(true)
      }else{
        setValidPhone(false)
      }
    }
  }
  return (
    <div className={styles["create-account"]}>
      <form className={styles["form-container"]}>
        <div className="page-heading">Create Account</div>
        <div className={styles["input-label"]}>NAME</div>
        <input type="text" className="form-control" placeholder="NAME" onChange={evt=>setName(evt.target.value)} value={name} style={{width: "300px"}}/>
        <div className={styles["input-label"]} style={{marginTop: "10px"}}>PHONE</div>
        <input type="number" className="form-control" placeholder="phone number" onChange={evt=>handlePhoneInput(evt.target.value)} value={phone} style={{width: "300px"}}/>
        <div className={styles["input-label"]} style={{marginTop: "10px"}}>ROLE</div>
        <Dropdown style={{ minWidth: "max-content", width: "300px"}}>
          <Dropdown.Toggle variant="primary" className="regular-button" id="dropdown-basic" style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
            {role.title || "SELECT ROLE"}
          </Dropdown.Toggle>
          <Dropdown.Menu style={{marginTop: "2px", padding: "0px", width: "100%", minWidth: "max-content"}}>
            {
              dataResources.roles.map(role=>{
                return <Dropdown.Item onClick={evt=>setRole(role)} >{role.title}</Dropdown.Item>
              })
            }
          </Dropdown.Menu>
        </Dropdown>
        <div className="radio-group" style={{margin: "10px 0px"}}>
          <div className="radio-input" onClick={evt=>setActive(1)}>
            <input type="radio" className="regular-radio-button" name="active" checked={active===1} />
            <div className={`input-label ${active===1?'active':''}`}>ACTIVATE</div>
          </div>
          <div className="radio-input" onClick={evt=>setActive(0)}>
            <input type="radio" className="regular-radio-button" name="active" checked={active===0} />
            <div className={`input-label ${active===0?'active':''}`}>DEACTIVATE</div>
          </div>
        </div>
        <Button className="regular-button" onClick={createAccount} disabled={!name || loader || !validPhone}>
          Create Account
        </Button>
        <div>
          {loader?
          <Loader
          type="ThreeDots"
          width={30}
          height={30}
          style={{color: "#144166"}}
          />
          :
          null
          }
        </div>
      </form>
    </div>
  );
}

export default CreateAccount;