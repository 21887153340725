import React from 'react';
import SimpleTable from '../../../../common/Tables/SimpleTable/SimpleTable';

function OrderPrescription({ rows }) {
  const prescriptionColumns = [
    { title: "Product", field: "name", headStyle: (cellData, index)=>{ return { minWidth: "300px"};}},
    { title: "Frequency", field: "frequency"},    
    { title: "Quantity", field: "quantity", number: true},
    { title: "Cart Quantity", field: "cartQuantity", number: true, headStyle: (cellData, index)=>{ return { minWidth: "150px"};}},
    { title: "Unit", field: "unit", headStyle: (cellData, index)=>{ return { minWidth: "90px"};} },
    { title: "Instructions", field: "instructions", headStyle: (cellData, index)=>{ return { minWidth: "650px"};} },
  ];
  if(!(rows && rows.length)) return <>NO DATA FOUND !!!</>;
  return (
    <div>
      <div className="page-heading secondary">Prescription Details</div>
      <SimpleTable rows={rows} columns={prescriptionColumns} />
    </div>
  )
}
export default OrderPrescription;