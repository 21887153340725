import React, {useState} from 'react';
import {BiHide} from 'react-icons/bi';
import {BiShowAlt} from 'react-icons/bi';
import styles from './PasswordToggle.module.css';

function PasswordToggle(props) {
  const [show, setShow] = useState(false);
  const passwordInput = React.createRef();
  const toggleVisibility = () =>{
    console.log("SHOW",show);
    console.log(passwordInput);
    if(!show) passwordInput.current.type="text";
    else passwordInput.current.type="password";
    setShow(!show);
  }
  return (
    <div className={styles["password-toggle"]}>
      <input type="password" ref={passwordInput} className={`form-control ${styles["password-input"]}`} value={props.password} disabled/>
      <div className={styles["toggle-button"]} onClick={evt=>toggleVisibility()}>
        {
        show?
        <BiShowAlt />
        :
        <BiHide />
        }
      </div>
    </div>
  );
}

export default PasswordToggle;