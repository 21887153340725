import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const SoSummaryDetials = ({ currentDetails, show, closeModal }) => {
  return (
    <div>
      <Modal show={show} onHide={closeModal}>
                <Modal.Header >
                    <Modal.Title>Summary detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>Payment Status:</strong> {currentDetails?.paymentStatus}</p>
                    <p><strong>Billed Amount:</strong> {currentDetails?.billedAmount ? currentDetails.billedAmount : 0}</p>
                    <p><strong>Pending Amount:</strong> {currentDetails?.pendingAmount ? currentDetails.pendingAmount : 0}</p>
                    <p><strong>Refund Amount:</strong> {currentDetails?.refundAmount ? currentDetails.refundAmount : 0}</p>
                    <p><strong>Reward Amount:</strong> {currentDetails?.refundAmount ? currentDetails.rewardAmount : 0}</p>
                    <p><strong>Patient Feedback:</strong> {currentDetails.patientFeedback ? currentDetails.patientFeedback : 'N/A'}</p>
                    <p><strong>Patient Rating:</strong> {currentDetails.patientRating ? currentDetails.patientRating : 'N/A'}</p>
                    <p><strong>Pass:</strong> {(currentDetails.pass === 'true') ? 'PASS' : 'WALLET'}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>Close</Button>
                </Modal.Footer>
            </Modal>
    </div>
  );
};

export default SoSummaryDetials;
