import axios from 'axios';
const userReducer = (state, action) => {
  if (state === undefined) return null;
  switch(action.type) {
    case "USER":
      return action.payload;
    case "CLEAR":
      localStorage.setItem("token", null);
      axios.defaults.headers.common["Authorization"] = '';
      return null;
    default: 
      return state;
  }
};
export default userReducer;
