import React from 'react'
import { Table } from 'react-bootstrap';
import styles from '../../../../common/Tables/SimpleTable/SimpleTable.module.css'
const OrderReceipt = ({orderDetails}) => {
    if(!orderDetails){
        return null;
    }
  return (
    <Table className={styles["simple-table"]} responsive style={{display: 'inline-block'}}>
        <tbody>
            <tr style={{height:'20px'}}></tr>
            <tr>
                
                <td style={{width:'400px', paddingLeft:'30%', border:'none'}}>
                    <div style={{width:'max-content', padding:'5px 10px', backgroundColor:'green',color:'#fff'}}>Order Receipt</div>
                </td>
                
                <td style={{border:'none'}}>
                    
                </td>
            </tr>
            <tr>
                <td style={{border:'none', paddingLeft:'30%'}}>
                    Payment Method
                </td>

                <td colspan="2" style={{border:'none', textAlign:'end'}}>
                    {orderDetails.payment_method}
                </td>
            </tr>
            <tr>
                <td style={{border:'none', paddingLeft:'30%'}}>
                    Subtotal
                </td>
                
                <td colspan="2" style={{border:'none', textAlign:'end'}}>
                    ₹{orderDetails?.order_subtotal}
                </td>
            </tr>
            {
                orderDetails?.total_discount>=0 &&
                <tr>

                <td style={{border:'none',paddingLeft:'30%'}}>
                    <div style={{width:'110%',borderBottom:'1px solid black',paddingBottom:'10px'}}>Discount</div>
                </td>
                
                <td colspan="2" style={{border:'none', textAlign:'end'}}>
                    <div style={{width:'100%',borderBottom:'1px solid black',paddingBottom:'10px'}}>₹{orderDetails.total_discount}</div>
                </td>
                </tr>
            }

            {
                orderDetails?.coupon_code && 
                <tr>

                <td style={{border:'none', paddingLeft:'30%'}}>
                    Coupon Code:{orderDetails.coupon_code}
                </td>
                
                <td colspan="2" style={{border:'none', textAlign:'end'}}>
                    -₹{Math.abs(orderDetails.discount).toFixed(2)}
                </td>
                </tr>

            }
            {
                orderDetails?.cod_charge>=0 &&
                <tr>

                    <td style={{border:'none', paddingLeft:'30%'}}>
                        Cod Charge
                    </td>
                    
                    <td colspan="2" style={{border:'none', textAlign:'end'}}>
                        ₹{orderDetails.cod_charge}
                    </td>
                </tr>
            }
            {
                orderDetails?.cred_coins>=0 &&
                <tr>

                    <td style={{border:'none', paddingLeft:'30%'}}>
                        CRED COINS
                    </td>
                    
                    <td colspan="2" style={{border:'none', textAlign:'end'}}>
                        ₹{orderDetails.cred_coins}
                    </td>
                </tr>
            }
            {
                orderDetails?.no_cost_emi>=0 &&
                <tr>

                    <td style={{border:'none', paddingLeft:'30%'}}>
                        No Cost EMI discount
                    </td>
                    
                    <td colspan="2" style={{border:'none', textAlign:'end'}}>
                        ₹{orderDetails.no_cost_emi}
                    </td>
                </tr>
            }
            <tr>

                <td style={{border:'none', paddingLeft:'30%'}}>
                    Shipping
                </td>
                
                <td colspan="2" style={{border:'none', textAlign:'end'}}>
                    {orderDetails.shipping}
                </td>
            </tr>
            {
                Number(orderDetails?.bundle_discount) > 0 &&
                <tr>

                    <td style={{border:'none', paddingLeft:'30%'}}>
                    Bundle Discount
                    </td>
                    
                    <td colspan="2" style={{border:'none', textAlign:'end'}}>
                        -₹{orderDetails.bundle_discount}
                    </td>
                </tr>
            }
            {
                orderDetails?.cashback &&
                <tr>

                    <td style={{border:'none', paddingLeft:'30%'}}>
                    Cashback
                    </td>
                    
                    <td colspan="2" style={{border:'none', textAlign:'end'}}>
                        {orderDetails.cashback}
                    </td>
                </tr>
            }
            {
                orderDetails?.digital_wallet &&
                <tr>

                    <td style={{border:'none', paddingLeft:'30%'}}>
                    Digital Wallet
                    </td>
                    
                    <td colspan="2" style={{border:'none', textAlign:'end'}}>
                        {orderDetails.digital_wallet}
                    </td>
                </tr>
            }
            <tr>

                <td style={{paddingLeft:'30%'}}>
                    Total
                </td>
                
                <td colspan="2" style={{textAlign:'end'}}>
                ₹{orderDetails.order_total}
                </td>
            </tr>
    
        </tbody>
    </Table>
  )
}

export default OrderReceipt