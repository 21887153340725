import React from 'react';
import SimpleTable from '../../../../common/Tables/SimpleTable/SimpleTable';
import styles from './styles.module.css';

function OrderNotes({ orderNotes, shipmentData }) {
  const [tabSelected, setTabSelected] = React.useState('orderNotes');
  const noteColumns = [
    { title: "SNo.", field: "sno"},
    { title: "Note", field: "note", headStyle: (cellData, index)=>{ return { minWidth: "700px"};}},
    { title: "Date", field: "date", headStyle: (cellData, index)=>{ return { minWidth: "200px"};}},
  ];
  const shipmentColumns = [
    { title: "SNo.", field: "sno"},
    { title: "Shipment Data", field: "shipment", headStyle: (cellData, index)=>{ return { minWidth: "700px"};}},
    { title: "Date", field: "date", headStyle: (cellData, index)=>{ return { minWidth: "200px"};}},
  ];
  const handleTabSelection = (tab) => () => {
    setTabSelected(tab);
  }
  if(!(orderNotes && shipmentData)) return <>NO DATA FOUND !!!</>;
  return (
    <div>
      <div className={styles['order-shipment-header']}>
        <div className={['page-heading secondary', tabSelected === "orderNotes" ? styles['active'] : ""].join(" ")} onClick={handleTabSelection('orderNotes')}> Order Notes </div>
        <div className={['page-heading secondary', tabSelected === "shipment" ? styles['active'] : ""].join(" ")} onClick={handleTabSelection('shipment')}>Shipment Data</div>
      </div>
      {
        tabSelected ==='shipment' ?
        <SimpleTable rows={shipmentData} columns={shipmentColumns} />
        :
        <SimpleTable rows={orderNotes} columns={noteColumns} />

      }
    </div>
  )
}
export default OrderNotes;