import axios from 'axios';
import React, {useState, useEffect} from 'react';
import { URL_LIST } from '../../../constants/apiList';
import { getStaticUrl, getAlternateStaticUrl } from '../../../constants/brandConfig';
import SimpleTable from '../../common/Tables/SimpleTable/SimpleTable';
import Loader from 'react-loader-spinner';
import styles from './CustomerWallet.module.css';
import { getBrand } from '../../../helpers/index';
function WalletPage(props) {
    const [walletData, setWalletData] = useState(null);
    const [loader, setLoader] = useState(true);
    const query = new URLSearchParams(props.location.search);
    const email = query.get("email")
    const columns = [
        { title: "Cashback/Spent Amount", field: "cashback_amount"},
        { title: "Roll Up Balance", field: "prefixSum"},
        { title: "Order Id", field: "order_no" },
        { title: "Code", field: "type" },
        { title: "Date", field: "added_on" },
        { title: "Expiry Date", field: "expiryDate" },
        { title: "Comment", field: "comment" },
    ]
    useEffect(()=>{
        const requestData = {
            email
        }
        const options = {
            headers:{
                brand: getBrand()
            }
        }
        axios.post(URL_LIST.WALLET, requestData, options).then((res)=>{
            setWalletData(res.data);
            setLoader(false);
        }).catch((e)=>{
            setLoader(false);
        })
      }, [])
      let walletTable = (
          <div className={styles['title-header']}>
              <div>User Wallet Not Found</div>
          </div>
      )
      if(walletData){
          walletTable = (
              <div className={styles['wallet-container']}>
                <div className={styles['title-header']}>Customer Wallet Page</div>
                <div className={styles['wallet-title']}>Wallet Points: {walletData.current || 0}</div>
                <div>
                    {walletData.history && <SimpleTable columns={columns} rows={walletData.history}/>}
                </div>
            </div>
          )
        //   setLoader(false);
      }
    return (
        loader? <Loader className={styles['loader']} type='TailSpin' width={200} height={200} color="#144166"/> : walletTable
    )
}
export default WalletPage;