const dataResources = {
  roles: [
    // {title: "Admin", value: "admin"},
    {title: "Agent", value: "agent"}
  ],
  statusList: {
    s: "Scheduled",
    c: "Completed",
    r: "Ringing",
    b: "Busy",
    so: "Switched off",
    d: "Disconnected",
    cb: "Call back",
    rs: "Rescheduled",
    wn: "Wrong no",
    ni: "Not interested",
    ns: "Not Suitable",
    re: "Repeat",
    l: "Language",
    op: "Ordered",
    nr: "No Ring",
    od: "Other doctor",
    co: "Cool off",
    t: "testing",
    na: "NA Completed",
    cn: "IVR Cancelled",
    rf: "Completed + Specialist Refer",
    otc: "Completed + No RX",
    p: "Pending",
    pl: "Pending + Language",
    pi: "Pending + Image",
    pc: "Pending + Callback",
    pbt: "Pending + Brand Transfer",
    pp: "Pending + Prescription",
    csp: "Completed + Suggest Procedure",
    csd: "Completed + Suggest Diagnostics",
    rc: "Rx Clear",
    cq: "Completed + Query"
  },  
  orderStatusList: [
    'limbo',
    'undelivered',
    'on-hold',
    'subscription',
    'future',
    'pending',
    'payment_aborted',
    'payment_attempted',
    'payment_failed',
    'processing',
    'rx_pending',
    'confirmed',
    'cod_verification',
    'processing',
    'complete',
    'delivered',
  ],
  clickpostStatusList: {
    1: "ORDER_PLACED",
    2: "PICKUP_PENDING",
    3: "PICKUP_FAILED",
    4: "PICKED_UP",
    5: "INTRANSIT",
    6: "OUT_FOR_DELIVERY",
    7: "NOT_SERVICEABLE",
    8: "DELIVERED",
    9: "FAILED_DELIVERY",
    10: "CANCELLED_ORDER",
    11: "RTO_REQUESTED",
    12: "RTO",
    13: "RTO_OUT_FOR_DELIVERY",
    14: "RTO_DELIVERED",
    15: "RTO_FAILED",
    16: "LOST",
    17: "DAMAGED",
    18: "SHIPMENT_DELAYED",
    19: "CONTACT_CUSTOMER_CARE",
    20: "SHIPMENT_HELD",
    21: "RTO_INTRANSIT",
    25: "OUT_FOR_PICKUP",
    26: "RTO_CONTACT_CUSTOMER_CARE",
    27: "RTO_SHIPMENT_DELAY",
    28: "AWB REGISTERED",
    30: "EXCHANGE_PICKUP",
    31: "EXCHANGE_INTRANSIT",
    32: "EXCHANGE_DELIVERED",
    101: "RETURN ORDER PLACED",
  },
  retryUnicommerceCode: [
    101,102,103,104,201,202,203,204
  ]
}

export default dataResources;