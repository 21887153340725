export const prod = {
  CS_DASHBOARD_DOMAIN: "https://api.mosaicwellness.in/csapi/",
  PRESCRIPTION_GENERATOR_DOMAIN: "https://api.mosaicwellness.in/prescription-generator/",
  MANMATTERS_DOMAIN: "http://woo.manmatters.pvt/",
  MANMATTERS_FE_DOMAIN: "https://manmatters.com/",
  BODYWISE_FE_DOMAIN: "https://bebodywise.com/",
  BODYWISE_BE_DOMAIN: "https://api.bebodywise.com/",
  MANMATTERS_MAGENTO_DOMAIN: "https://api.manmatters.com/",
  MANMATTERS_SLIM_DOMAIN: "https://slim.manmatters.com/",
  LITTLEJOYS_DOMAIN: "https://ourlittlejoys.com/",
  LITTLEJOYS_MAGENTO_DOMAIN: "https://api.ourlittlejoys.com/",
  PORTAL:{
    LJ:"https://api.ourlittlejoys.com/portal/",
    MM:"https://api.manmatters.com/portal/",
    BW:"https://api.bebodywise.com/portal/"
  }
};
