import React, {useState, useEffect} from 'react';
import styles from './OrderDetails.module.css';
import Table from '../../common/Tables/Table';
import { GoChevronDown, GoChevronUp} from 'react-icons/go';

function RefundDetails (props){
    const [rows, setRows] = useState([]);
    const [show, setShow] = useState(false);

    const columns = [
      { title: "Order ID", field: "orderId" },    
      { title: "Refund Id", field: "refundId" },
      { title: "Refund Reference Id", field: "refundReferenceId" },
      { title: "Refund Status", field: "refundStatus" },
      { title: "Refund Source Amount", field: "refundedSourceAmt" },
      { title: "Refund Wallet Amount", field: "refundedWalletAmt" },
      { title: "Initiated By", field: "initiatedBy" },
    ];
  
    const generateRows = (orders) => {
      setRows(orders);
    }
  
    useEffect(()=>{
      generateRows(props.orders);
    },[props.orders]);

    return (
      <div className={styles["order-details"]}>
        <div style={{display: "flex", marginBottom: "10px",}}>
          <div className="page-heading" style={{minWidth: "200px"}}>
          Refund Details
          </div>
          {
            show?
            <div className={styles["toggle-button"]} onClick={evt=>setShow(false)}>
              HIDE <GoChevronUp style={{width: "20px", height: "20px"}} />
            </div>
            :
            <div className={styles["toggle-button"]} onClick={evt=>setShow(true)}>
              SHOW <GoChevronDown style={{width: "20px", height: "20px"}} />
            </div>
          }
        </div>
        {
          show?
          <div className={styles["main-content"]}>
            <Table title="Orders" columns={columns} rows={rows} search={true} pageSize={rows.length>10?10:rows.length}/>
          </div>
          :
          null
        }
      </div>    
    )
    
}

export default RefundDetails;