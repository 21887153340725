import React, {useEffect, useState} from 'react';
import styles from './ListUsers.module.css';
import axios from 'axios';
import { URL_LIST } from '../../../constants/apiList';
import Table from '../../common/Tables/Table';
import PasswordToggle from '../../common/PasswordToggle/PasswordToggle';
import { Button } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import {toast} from 'react-toastify';

function ListUsers(props) {
  const [rows, setRows] = useState([]);
  const [loader,setLoader] = useState(false);
  const columns = [
    {title: "ID", field: "id"},
    {title: "Name", field: "name"},
    {title: "Password", field: "password"},
    {title: "Active", field: "active"},
    {title: "Action", field: "action"},
  ];
  const updateActiveStatus = async (active,userId) => {
    const requestData = {
      active,
      userId,
    };
    await axios.post(URL_LIST.UPDATE_ACTIVE_STATUS, requestData)
    .then(res=>{
      console.log(res);
      if(res.data.success) {
        listUsers();
      }
      else {
        toast.error(res.data.message);
      }
    })
    .catch(err=>{
      toast.error("Unable to update active status");
      console.log("Error in /updateActiveStatus", err);
    })
    
  }
  const generateRows = (data) => {
    const tempRows = [];
    for(const userData of data) {
      const finalRow = {...userData};
      finalRow.password = <PasswordToggle password={finalRow.password} /> 
      finalRow.action = (<div>
        {
          finalRow.active===1?
          <Button variant="secondary" onClick={evt=>updateActiveStatus(0,finalRow.id)}>Deactivate</Button>
          :
          <Button variant="info" style={{minWidth: "103px"}} onClick={evt=>updateActiveStatus(1,finalRow.id)}>Activate</Button>
        }
      </div>);
      tempRows.push(finalRow);
    }
    setRows(tempRows);
  }
  const listUsers = async () => {
    setLoader(true);
    await axios.post(URL_LIST.LIST_USERS, {})
    .then(res=>{
      console.log(res);
      if(res.data.success) {
        generateRows(res.data.users);
      }
      else {
        toast.error(res.data.message);
      }
      setLoader(false);
    })
    .catch(err=>{
      setLoader(false);
      toast.error("Unable to List Users");
      console.log("Error in /listUsers", err);
    })
  }
  useEffect(listUsers,[]);
  return (
    <div className={styles["list-users"]}>
      {
        loader?
        <Loader
        type="ThreeDots"
        width={40}
        height={40}
        color="#144166"
        />
        :
        <Table title="Users" columns={columns} rows={rows} pageSize={rows.length>10?10:rows.length} search={true} />
      }
    </div>
  );
}

export default ListUsers;