import React from 'react'
import {GrFormClose} from 'react-icons/gr'
function CancelOrder({setShowModal, cancelReason, setCancelReason}) {
  return (
    <div>
        <div style={{display:'flex', justifyContent:'space-between', fontSize:'28px'}}>
            <div></div>
            <div>Confirm Cancellation</div>
            <div onClick={()=>setShowModal(false)} style={{cursor:'pointer'}}><GrFormClose/></div>
        </div>
        <div style={{marginTop:'20px'}}>
            <label>Add Cancellation Reason</label>
            <textarea name="" style={{width:'100%', height:'150px'}} value={cancelReason} onChange={(e)=>setCancelReason(e.target.value)}></textarea>
        </div>
    </div>
  )
}

export default CancelOrder