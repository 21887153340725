import axios from 'axios';
import React,{ useState } from 'react'
import { Button } from "react-bootstrap";
import { URL_LIST } from '../../../constants/apiList';
import { getBrand } from '../../../helpers';
import { toast } from 'react-toastify';
import styles from './styles.module.css';
import Table from '../../common/Tables/Table';

const UserInfo = () => {

  const [searchVal,setSearchVal] = useState('')
  const [userInfo,setUserInfo] = useState([])
  const columns = [
    { title: "S No", field: "sno" },
    { title: "Customer Id",field: "customerId" },
    { title: "First Name", field: "firstname" },
    { title: "City", field: "city" },
    { title: "Region", field: "region" },
    { title: "Phone No.", field: "phone_number" },
    { title: "Last Order Date", field: "lastOrderDate" },
    { title: "Platform", field: "platform" },
    { title: "Order Count", field: "orderCount" },
    { title: "Wallet Balance", field: "wallet_balance" },
  ]

  const onSearch = async ()=>{
    try{
      const brand = getBrand();
      const headers = {
        "brand": brand
      }
      const res = await axios.post(URL_LIST.USER_INFO, {customersIds: searchVal},{headers} )
      if(!res?.data?.data?.length){
        toast.info("No Users Found!");
      }else{
        const data = res.data.data.map((item,index)=>{
          return {
            sno:index+1,
            ...item
          }
        })
        setUserInfo(data)
      }
    }catch(e){
      toast.error("Unable to search customer details");
    }
  }

  return (
    <div className={styles["main-container"]}>
         <div className="page-heading" style={{minWidth: "200px"}}>User Info</div>
         <div className={styles["text-area-container"]} >
          <textarea className={"form-control "+styles["text-area-input"]} placeholder="Enter comma separated userIds or on newline"  onChange={e=>setSearchVal(e.target.value)}  />
            <Button
              className="regular-button"
              disabled={!searchVal}
              onClick={onSearch}
            >
              Search
            </Button>
        </div>
        {
        !!userInfo.length && 
        <Table title="User Details" columns={columns} rows={userInfo} search={false} pageSize={userInfo.length>20?20:userInfo.length} exportData={false}/>
        }
    </div>
  )
}

export default UserInfo