export const stg = {
  CS_DASHBOARD_DOMAIN: "https://stg.api.mosaicwellness.in/csapi/",
  PRESCRIPTION_GENERATOR_DOMAIN: "https://stg.api.mosaicwellness.in/prescription-generator/",
  MANMATTERS_DOMAIN: "http://stg.manmatters.pvt/",
  MANMATTERS_FE_DOMAIN: "https://stg.manmatters.com/",
  BODYWISE_FE_DOMAIN: "https://stg.bebodywise.com/",
  BODYWISE_BE_DOMAIN: "https://stg.api.bebodywise.com/",
  MANMATTERS_MAGENTO_DOMAIN: "https://stg-oms.manmatters.com/",
  MANMATTERS_SLIM_DOMAIN: "https://stg-slim.manmatters.com/",
  LITTLEJOYS_DOMAIN: "https://stg.ourlittlejoys.com/",
  LITTLEJOYS_MAGENTO_DOMAIN: "https://stg-oms.ourlittlejoys.com/",
  PORTAL:{
    LJ:"https://stg.api.ourlittlejoys.com/portal/",
    MM:"https://stg.api.manmatters.com/portal/",
    BW:"https://stg.api.bebodywise.com/portal/"
  }
};
