import React, {useState} from 'react';
import styles from './SubscriptionDetails.module.css';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { URL_LIST, DOMAIN_LIST } from '../../../constants/apiList';
import { STATIC_URL_KEYS, getStaticUrl, getAlternateStaticUrl } from '../../../constants/brandConfig';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import Table from '../../common/Tables/Table';
import moment from 'moment';
import { GoChevronDown, GoChevronUp} from 'react-icons/go';

function SubscriptionDetails(props) {
  const [loader, setLoader] = useState(false);
  const [rows, setRows] = useState([]);
  const [show, setShow] = useState(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const columns = [
    { title: "S No", field: "sno" },
    { title: "Date", field: "date" },
    { title: "Order ID", field: "orderId" },    
    { title: "Active", field: "active" },    
    { title: "Show Details", field: "showDetails"},
  ];
  const showSubscriptionDetails = (sb) => {
    setSubscriptionDetails(sb);
    setShowDetailsModal(true);
  }
  const generateRows = (subscriptions) => {
    const finalRows = [];
    let index = 1;
    for(const sb of subscriptions) {
      const sno = index;
      index++;
      const finalRow = {sno};
      finalRow.date = sb.start_date;
      finalRow.orderId = sb.subscription_order_id;
      finalRow.active = sb.subscription_status;
      finalRow.showDetails = <Button className="regular-button" size="sm" onClick={evt=>showSubscriptionDetails(sb)}>Show More</Button>
      finalRows.push(finalRow);
    }
    setRows(finalRows);
  }
  const searchSubscriptions = async () => {
    setLoader(true);
    setRows([]);
    setShow(true);
    const requestData = {
      altUrl: getStaticUrl(STATIC_URL_KEYS.CS_SUBSCRIPTION),
      url: getAlternateStaticUrl(STATIC_URL_KEYS.CS_SUBSCRIPTION),
      requestBody : {
        email: props.email,
        secret: "f2cf0743f6295cc165b2a0312404f104",
      }
    }
    axios.post(URL_LIST.SEND_POST_REQUEST, requestData)
    .then(res=>{
      if(res.data.status) {
        generateRows(res.data.result.subscriptions);
      }
      else {
        toast.error(res.data.message);
      }
      setLoader(false);
    })
    .catch(err=>{
      setLoader(false);
      toast.error("Unable to search customer details");
      console.log("ERROR IN /searchCustomer", err);
    })
  }
  return (
    <div className={styles["subscription-details"]}>
      <div style={{display: "flex", marginBottom: "10px",}}>
        <div className="page-heading" style={{minWidth: "200px"}}>
        Subscription Details
        </div>
        {
          show?
          <div className={styles["toggle-button"]} onClick={evt=>setShow(false)}>
            HIDE <GoChevronUp style={{width: "20px", height: "20px"}} />
          </div>
          :
          <div className={styles["toggle-button"]} onClick={searchSubscriptions}>
            SHOW <GoChevronDown style={{width: "20px", height: "20px"}} />
          </div>
        }
      </div>
      {
        show?
        <div className={styles["main-content"]}>
          {
            loader?
            <Loader
            type="ThreeDots"
            width={40}
            height={40}
            color="#144166"
            />
            :
            <Table title="Subscriptions" columns={columns} rows={rows} search={true} pageSize={rows.length>10?10:rows.length}/>
          }
        </div>
        :
        null
      }
      <Modal
        centered
        show={showDetailsModal}
        size="lg"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {
            loader?
            <Loader type="ThreeDots" width={30} height={30} color="#144166" />
            :
            <div className={styles["info-card"]}>
              {
                subscriptionDetails.subscription_order_id?
                <div>
                  <div className="page-heading secondary">Subscription Details</div>
                  <div className={styles["row"]}>
                    <div className={styles["field"]}>Date</div>
                    <div className={styles["value"]}>{subscriptionDetails.start_date?moment(subscriptionDetails.start_date, 'DD/MM/YYYY').format("DD MMM YYYY"):"NA"}</div>
                  </div>
                  <div className={styles["row"]}>
                    <div className={styles["field"]}>Subscription Order ID</div>
                    <div className={styles["value"]}>{subscriptionDetails.subscription_order_id?subscriptionDetails.subscription_order_id:"NA"}</div>
                  </div>
                  <div className={styles["row"]}>
                    <div className={styles["field"]}>Subscription Product ID</div>
                    <div className={styles["value"]}>{subscriptionDetails.subscription_product_id?subscriptionDetails.subscription_product_id:"NA"}</div>
                  </div>
                  <div className={styles["row"]}>
                    <div className={styles["field"]}>Product</div>
                    <div className={styles["value"]}>{subscriptionDetails.prod_name?subscriptionDetails.prod_name:"NA"}</div>
                  </div>
                  <div className={styles["row"]}>
                    <div className={styles["field"]}>Product Category</div>
                    <div className={styles["value"]}>{subscriptionDetails.prod_cat?subscriptionDetails.prod_cat:"NA"}</div>
                  </div>
                  <div className={styles["row"]}>
                    <div className={styles["field"]}>Quantity</div>
                    <div className={styles["value"]}>{subscriptionDetails.quantity?subscriptionDetails.quantity:"NA"}</div>
                  </div>
                  <div className={styles["row"]}>
                    <div className={styles["field"]}>Pending Orders</div>
                    <div className={styles["value"]}>{subscriptionDetails.pending_orders?subscriptionDetails.pending_orders:"NA"}</div>
                  </div>
                  <div className={styles["row"]}>
                    <div className={styles["field"]}>Processed Orders</div>
                    <div className={styles["value"]}>{subscriptionDetails.processed_orders?subscriptionDetails.processed_orders:"NA"}</div>
                  </div>
                  <div className={styles["row"]}>
                    <div className={styles["field"]}>Installments</div>
                    <div className={styles["value"]}>{subscriptionDetails.installments?subscriptionDetails.installments:"NA"}</div>
                  </div>
                  <div className={styles["row"]}>
                    <div className={styles["field"]}>Delivery Interval</div>
                    <div className={styles["value"]}>{subscriptionDetails.delivery_interval?subscriptionDetails.delivery_interval+" Days":"NA"}</div>
                  </div>
                  <div className={styles["row"]}>
                    <div className={styles["field"]}>Rating</div>
                    <div className={styles["value"]}>
                      {subscriptionDetails.rating_score?`${subscriptionDetails.rating_score} (${subscriptionDetails.rating_count} Ratings)`:"NA"}
                    </div>
                  </div>
                  <div className={styles["row"]}>
                    <div className={styles["field"]} style={{color: "#00a066"}}>Subscription Price</div>
                    <div className={styles["value"]} style={{color: "#00a066"}}>{subscriptionDetails.subscription_price?subscriptionDetails.subscription_price+"/-":"NA"}</div>
                  </div>
                  <div className={styles["seperator"]}></div>
                  <div className={styles["row"]} style={{alignItems: "flex-start"}}>
                    <div className={styles["field"]}>Subscription Statistics</div>
                    <div className={styles["value"]}>
                      {
                        subscriptionDetails.delivery_dates?
                        <>
                        {
                          subscriptionDetails.delivery_dates.map(deliveryDate=>{
                            return (<div className={styles["statistic-card"]}>
                              <div className={styles["small-row"]}>
                                <div className={styles["field"]}>Pack No</div>
                                <div className={styles["value"]}>{deliveryDate.pack_no?deliveryDate.pack_no:"NA"}</div>
                              </div>
                              <div className={styles["small-row"]}>
                                <div className={styles["field"]}>Shipping Date</div>
                                <div className={styles["value"]}>{deliveryDate.shipping_date?deliveryDate.shipping_date:"NA"}</div>
                              </div>
                              <div className={styles["small-row"]}>
                                <div className={styles["field"]}>Order ID</div>
                                <div className={styles["value"]}>{deliveryDate.subscription_order_id?deliveryDate.subscription_order_id:"NA"}</div>
                              </div>
                              <div className={styles["small-row"]}>
                                <div className={styles["field"]}>Subscription Status</div>
                                <div className={styles["value"]}>{deliveryDate.subscription_status?deliveryDate.subscription_status:"NA"}</div>
                              </div>

                            </div>)
                          })
                        }
                        </>
                        :
                        "NA"
                      }
                      
                    </div>
                  </div>

                </div>
                :
                "NO DATA FOUND"
              }
              <div className={styles["row"]}>
                <Button
                  className="regular-button"
                  style={{ marginTop: "20px", width: "100%"}}
                  onClick={() => {
                    setShowDetailsModal(false);
                  }}
                >
                  Close
                </Button>
              </div>
            </div>
          }            
        </div>
      </Modal>      

    </div>    
  )
}
export default SubscriptionDetails;