import { store } from '../store';
import { DOMAIN_LIST } from './apiList';
import { getParsedValue, getBrand } from '../helpers';

const brandConfigs = {
  "MM": {
    LOGO: 'https://ik.manmatters.com/mosaic-wellness/image/upload/v1632471322/Man%20Matters/misc/new-man-logo_White_1.png',
    FAVICON: "https://ik.manmatters.com/mosaic-wellness/image/upload/v1622870519/partner-dashboard/favicon%20icons/unnamed.webp",
    TITLE: "DASHBOARD | MAN MATTERS",
  },
  "BW": {
    LOGO: 'https://ik.manmatters.com/mosaic-wellness/image/upload/v1606295435/staging/Home/Images/bodywise-logo.png',
    FAVICON: "https://ik.manmatters.com/mosaic-wellness/image/upload/v1622873512/partner-dashboard/favicon%20icons/download.png",
    TITLE: "DASHBOARD | BODYWISE",
  },
  "LJ": {
    LOGO: 'https://ik.manmatters.com/mosaic-wellness/image/upload/v1645519913/Little%20Joys/HeaderFooter/Logo.png',
    FAVICON: "https://ik.manmatters.com/mosaic-wellness/image/upload/v1647432187/Little%20Joys/LOGO-16x16.png",
    TITLE: "DASHBOARD | LITTLE JOYS",
  },
}

const staticUrls = {
  "MM": {
    CS_USER: `${DOMAIN_LIST.MANMATTERS_DOMAIN}?wc-ajax=cs_user`,
    CS_SUBSCRIPTION: `${DOMAIN_LIST.MANMATTERS_DOMAIN}?wc-ajax=cs_subscription`,
    CS_ORDER: `${DOMAIN_LIST.MANMATTERS_DOMAIN}?wc-ajax=cs_order`,
    ORDER_NOTES: `${DOMAIN_LIST.MANMATTERS_DOMAIN}?wc-ajax=order_notes`,
    UPLOAD_IMAGE: `${DOMAIN_LIST.MANMATTERS_FE_DOMAIN}upload-image?schedule_token={{schedule_token}}`,
    WALLET_LINK: `/customer-wallet?email={{email}}`,
    CANCEL_AND_REFUND: `${DOMAIN_LIST.MANMATTERS_DOMAIN}oms/rest/V1/cancel-and-refund`,
    CS_WALLET: `${DOMAIN_LIST.PORTAL.MM}cs/wallet`,
    VERIFY_OTP: `${DOMAIN_LIST.PORTAL.MM}auth/verify-cs-otp`
  },
  "BW": {
    CS_USER: `${DOMAIN_LIST.PORTAL.BW}cs/user`,
    CS_SUBSCRIPTION: `${DOMAIN_LIST.BODYWISE_BE_DOMAIN}Magento2/rest/V1/cs/subscriptions`,
    CS_ORDER: `${DOMAIN_LIST.BODYWISE_BE_DOMAIN}Magento2/rest/V1/cs/order`,
    ORDER_NOTES: `${DOMAIN_LIST.BODYWISE_BE_DOMAIN}Magento2/rest/V1/cs/order/notes`,
    UPLOAD_IMAGE: `${DOMAIN_LIST.BODYWISE_FE_DOMAIN}upload-image?schedule_token={{schedule_token}}`,
    WALLET_LINK: `/customer-wallet?email={{email}}`,
    CANCEL_AND_REFUND: `${DOMAIN_LIST.BODYWISE_BE_DOMAIN}Magento2/rest/V1/cancel-and-refund`,
    CS_WALLET: `${DOMAIN_LIST.PORTAL.BW}cs/wallet`,
    VERIFY_OTP: `${DOMAIN_LIST.PORTAL.BW}auth/verify-cs-otp`
  },
  "MM_ALT": {
    CS_USER: `${DOMAIN_LIST.PORTAL.MM}cs/user`,
    CS_SUBSCRIPTION: `${DOMAIN_LIST.MANMATTERS_MAGENTO_DOMAIN}oms/rest/V1/cs/subscriptions`,
    CS_ORDER: `${DOMAIN_LIST.MANMATTERS_MAGENTO_DOMAIN}oms/rest/V1/cs/order`,
    ORDER_NOTES: `${DOMAIN_LIST.MANMATTERS_MAGENTO_DOMAIN}oms/rest/V1/cs/order/notes`,
    UPLOAD_IMAGE: `${DOMAIN_LIST.MANMATTERS_FE_DOMAIN}upload-image?schedule_token={{schedule_token}}`,
    WALLET_LINK: `/customer-wallet?email={{email}}`,
    CANCEL_AND_REFUND: `${DOMAIN_LIST.MANMATTERS_MAGENTO_DOMAIN}oms/rest/V1/cancel-and-refund`,
    CS_WALLET: `${DOMAIN_LIST.PORTAL.MM}cs/wallet`,
    VERIFY_OTP: `${DOMAIN_LIST.PORTAL.MM}auth/verify-cs-otp`
  },
  "LJ": {
    CS_USER: `${DOMAIN_LIST.PORTAL.LJ}cs/user`,
    CS_SUBSCRIPTION: `${DOMAIN_LIST.LITTLEJOYS_MAGENTO_DOMAIN}oms/rest/V1/cs/subscriptions`,
    CS_ORDER: `${DOMAIN_LIST.LITTLEJOYS_MAGENTO_DOMAIN}oms/rest/V1/cs/order`,
    ORDER_NOTES: `${DOMAIN_LIST.LITTLEJOYS_MAGENTO_DOMAIN}oms/rest/V1/cs/order/notes`,
    UPLOAD_IMAGE: `${DOMAIN_LIST.LITTLEJOYS_DOMAIN}upload-image?schedule_token={{schedule_token}}`,
    WALLET_LINK: `/customer-wallet?email={{email}}`,
    CANCEL_AND_REFUND: `${DOMAIN_LIST.LITTLEJOYS_MAGENTO_DOMAIN}oms/rest/V1/cancel-and-refund`,
    CS_WALLET: `${DOMAIN_LIST.PORTAL.LJ}cs/wallet`,
    VERIFY_OTP: `${DOMAIN_LIST.PORTAL.LJ}auth/verify-cs-otp`
  }
};

export const getStaticUrl = (key = "", parameters = []) => {
  const brand = getBrand();
  const finalStaticUrls = staticUrls[brand] || {};
  const url = finalStaticUrls[key] || "";
  const parsedUrl = getParsedValue(url,parameters);
  return parsedUrl;
}

export const getAlternateStaticUrl = (key = "", parameters = []) => {
  const brand = "MM_ALT";
  const finalStaticUrls = staticUrls[brand] || {};
  const url = finalStaticUrls[key] || "";
  const parsedUrl = getParsedValue(url,parameters);
  return parsedUrl;
}

export const getBrandConfig = (key = "", parameters = []) => {
  const brand = getBrand();
  const finalBrandConfigs = brandConfigs[brand] || {};
  const config = finalBrandConfigs[key] || "";
  const parsedConfig = getParsedValue(config,parameters);
  return parsedConfig;
}

const generateKeys = (configs) => {
  const brand = getBrand();
  const finalConfigs = configs[brand] || {};
  const keys = Object.keys(finalConfigs) || [];
  const configKeys = {};
  keys.forEach(key=>{
    configKeys[key] = key;
  })
  return configKeys;
}

export const STATIC_URL_KEYS = generateKeys(staticUrls);
export const BRAND_CONFIG_KEYS = generateKeys(brandConfigs)