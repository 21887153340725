import React, { useState, useEffect} from 'react';
import { Table } from 'react-bootstrap';
import styles from './SimpleTable.module.css';

function SimpleTable({rows, columns}) {
  let currentSum = 0
  const reversedRows = [...rows].reverse();
  const supdatedRows = reversedRows.map((item) => {
    currentSum += parseInt(item.cashback_amount, 10);
    return {
      ...item,
      prefixSum: currentSum,
    };
  }).reverse();
  return (
    <Table className={styles["simple-table"]} responsive>
      <thead>
        <tr>
          {
            columns.map((col,index)=>{
              return <th className={col.number?styles["number"]:''} style={col.headStyle?col.headStyle(col,index):{}}>
                {col.title}
              </th>
            })
          }
        </tr>
      </thead>
      <tbody>
        {
          rows.length?
          supdatedRows.map(row=>{
            return (
              <tr>
                {
                   columns.map(col=>{
                    return (
                      <td>
                        <div className={`${styles["td-outer"]} ${col.number?styles["number"]:''}`}>
                          <div className={`${styles["td-inner"]} ${col.number?styles["number"]:''}`}>
                            {row[col.field]}
                          </div>
                        </div>
                      </td>
                    )
                  })
                }
              </tr>
            );
          })
          :
          <tr>
            <td colspan={columns.length}>
              <div className={styles["no-data-message"]}>No Record!</div>
            </td>
          </tr>
        }
      </tbody>
    </Table>
  );
}
export default SimpleTable;