import React, { useState, useRef } from 'react';
import styles from './styles.module.css'
import { Button } from 'react-bootstrap';
import { URL_LIST } from '../../../../../constants/apiList'
import Loader from 'react-loader-spinner';
import axios from 'axios';
import { Table } from 'react-bootstrap';
import tableStyles from '../../../../common/Tables/SimpleTable/SimpleTable.module.css'

const RefundModal = ({orderDetails}) => {
    let refunded = null;
    if(orderDetails.refundDetails.intiatedDetails){
        refunded = orderDetails.refundDetails.intiatedDetails;
    }
    let refundBreakdown = null;
    if(orderDetails?.refundDetails?.refundBreakdown){
        refundBreakdown = orderDetails?.refundDetails?.refundBreakdown;
    }
    const [refundInitialised, setRefundInitialised] = useState(refunded? "response":"init");
    const [refundData, setRefundData] = useState(refunded?refunded:{});
    const [refundBreakdownData, setRefundBreakdownData] = useState(refundBreakdown?refundBreakdown:false);
    const [refundTo, setRefundTo] = useState(refunded? refunded.refund_to:"source");
    const [showModal, setShowModal] = useState(true);
    const [reason, setReason] = useState("");
    const [partialAmount, setPartialAmount] = useState(null);
    const note = useRef(null);
    const partialValue = useRef(null);
    if(!orderDetails){
        return null;
    }
    
    const status = orderDetails.order_status;
    const refundTitle = `Refund a ${status === "complete"? "Delivered": status} Order`
    const subTitle1 = `You are refunding a ${status === "complete"? "Delivered": status} order.`
    const subTitle2 = status === "complete"? "Refund will be processed as per return policy." :`This will cancel the order and refund will be created`;
    let refundResponse = Object.keys(refundData).length>0?(<div>
        <div className={styles["refund_title"]}>{`${refundData.refundId? "Refund Successful": refundData.message || "Failed"}`}</div>
        {refundData.refundId && ( <div className={styles["refund_secondary_title"]}>
            <div>
                {`Refund Id = ${refundData.refundId}`}
            </div>
            <div>
                This is an internal id, Refund will be processed in
            </div>
            <div>
                {`minimum 5-7 business days in cusomer ${refundTo ==="source"? "source account": "wallet"}.`}
            </div>
            {refundBreakdownData && 
            <Table className={tableStyles["simple-table"]} responsive style={{display: 'inline-block'}}>
                <tbody>
                    <tr style={{height:'20px'}}></tr>
                        {refundBreakdownData.map(refundKey=>{ 
                            return (
                                <tr>
                                    <td style={{border:'none'}}>
                                        {refundKey?.title}
                                    </td>

                                    <td colspan="2" style={{border:'none', textAlign:'end'}}>
                                        {refundKey?.value}
                                    </td>
                                </tr>
                            )
                        })}
                </tbody>
            </Table>}
        </div> )}
    </div>): null;
    const initiateRefund = async ()=>{
        const refundurl = URL_LIST.CANCEL_AND_REFUND;
        if(partialAmount && partialAmount == 0 || (partialAmount > orderDetails.refundDetails.maxRefund)){
            alert("Amount cannot be greater than total amount");
            return;
        }
        try{
            setRefundInitialised("loader");
            const reqBody = {
                incrementId: orderDetails.order_id,
                requestedBy:"cs",
                note: reason||"",
                requestedAmount: partialAmount || orderDetails.order_total,
                refundTo: refundTo
            }
            const refundStatus = await axios.post(refundurl, reqBody);
            setRefundInitialised("response")
            setRefundData(refundStatus.data);
            setRefundBreakdownData(refundStatus.data?.refundBreakdown);
        }catch(e){
            console.log(e.message)
        }
    }

    const loader = (<div style={{width:"100%", textAlign:"center"}}><Loader type="ThreeDots" width={30} height={30} style={{textAlign:"centre"}} color="#144166" /></div>);
    
    const prompt = (
        <div>
            <div className={styles["refund_prompt"]}>
                Are You Sure?
            </div>
            <div className={styles["refund_prompt_buttons"]}>
                <div>
                <Button onClick={()=>{initiateRefund()}} variant="danger" style={{padding:"5px 50px", margin:"5px"}} size="sm" disabled={!orderDetails.tracking_url}>
                    Yes  
                </Button>
                </div>
                <div>
                <Button onClick={()=>{setShowModal(false)}} variant="dark" size="sm" style={{padding:"5px 50px", margin:"5px"}} disabled={!orderDetails.tracking_url}>
                    No
                </Button>
                </div>
            </div>
        </div>
    )
    const refundInitialise = (<div className={styles["refund_box"]}>
                                <div className={styles["refund_title"]} >
                                    {refundTitle}
                                </div>
                                <div className={styles["refund_secondary_title"]}>
                                    <div>
                                        {subTitle1}
                                    </div>
                                    <div>
                                        {subTitle2}
                                    </div>
                                </div>
                                { orderDetails.refundDetails.refundType === "R" ?
                                    <div className={styles["redund_note"]}>
                                        <div>
                                            NOTE
                                        </div>
                                        <div>
                                            Refund to source amount will only happen once the products are RTO
                                        </div>
                                    </div> : null
                                }
                                <div>
                                    <div className={styles["refund_secondary_title"]}>
                                        {`Enter reason for refund (mandatory)`}
                                    </div>
                                    <div>
                                    <textarea className={styles["refund_reason"]} ref={note}></textarea>
                                    </div>
                                </div>
                                {orderDetails.refundDetails.partialEligible && 
                                 <div className={styles["partial_refund_option"]}>
                                     <div style={{width:"30%"}}>Enter Refund Amount (Max: {orderDetails.refundDetails.maxRefund})</div>
                                     <div><input style={{margin:"0px 5px"}} type="number" ref={partialValue}/></div>
                                     <div style={{color:"red"}}>refund amount cannot be greater than paid amount</div>
                                 </div>
                                }
                                <div className={styles["refund_buttons"]}>
                                    <div className={styles["refund_option"]}>
                                    <Button onClick={()=>{if(note.current.value.length<=0){ alert("Please provide reason"); return;}; setReason(note.current.value); setRefundTo("source"); setRefundInitialised("prompt"); setPartialAmount(partialValue.current? partialValue.current.value :0)}} variant="danger" size="sm" disabled={!orderDetails.tracking_url}>
                                        Refund to Source
                                    </Button>
                                    </div>
                                    {/* <div className={styles["refund_option"]}>
                                    <Button onClick={()=>{setReason(note.current.value); setRefundTo("wallet"); setRefundInitialised("prompt"); setPartialAmount(partialValue.current? partialValue.current.value :0)}} variant="danger" size="sm" disabled={!orderDetails.tracking_url}>
                                        Refund to Wallet   
                                    </Button>
                                    </div> */}
                                    <div className={styles["refund_option"]}> 
                                    <Button onClick={()=>{setShowModal(false)}} variant="dark" size="sm" disabled={!orderDetails.tracking_url}>
                                        Cancel
                                    </Button>
                                    </div>
                                </div>
                            </div>)
  const modalMap = {
      "init": refundInitialise,
      "response":refundResponse,
      "prompt":prompt,
      "loader":loader
  }
  const refundInfo = modalMap[refundInitialised];
  return (
    showModal? refundInfo:null
  )
}

export default RefundModal