import React, { useMemo } from "react";
import MaterialTable from "material-table";

export default function Table({
  title,
  columns,
  rows,
  pageSize,
  search,
  pagin,
  rowStyle,
  actions,
  exportData=true
}) {
  return (
    <div id={title.replace(/\s/g, "").toLowerCase()}>
      <MaterialTable
        columns={columns}
        data={useMemo(() => rows, [rows])}
        title={title}
        options={{
          exportButton: !exportData?exportData:true,
          exportAllData: !exportData?exportData:true,
          pageSize: pageSize ? pageSize : 1,
          paging: pagin === undefined && rows.length>pageSize ? true : pagin,
          search: search ? true : false,
          sorting: false,
          headerStyle: {
            backgroundColor: "#003459",
            color: "#FFF",
            padding: "10px 15px",
          },
          cellStyle: { padding: "10px 15px" },
          rowStyle
        }}
        actions = {actions}
        // style={{ margin: "20px 10px 10px" }}
      />
    </div>
  );
}
