import React, {useState} from 'react';
import styles from './CustomerSOPasses.module.css';
import { Button, Modal, Dropdown } from 'react-bootstrap';
import axios from 'axios';
import { URL_LIST } from '../../../constants/apiList';
import { getParsedOrderId } from '../../../helpers';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import Table from '../../common/Tables/Table';


function CustomerSOPasses(props) {
  const [searchBy, setSearchBy] = useState("phone");
  const [modalContentType, setModalContentType] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [loader, setLoader] = useState(false);
  const [customerPasses, setCustomerPasses] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [expirePassId, setExpirePassId] = useState(null);
  const [patientId, setPatientId] = useState(null);
  const [passRole, setPassRole] = useState(null);
  const [roles, setRoles] = useState([]);
  const [numberOfPassesToAdd, setNumberOfPassesToAdd] = useState(null);
  const searchParameters = [
    { title: "PHONE", value: "phone"},
    { title: "EMAIL", value: "email"},
    { title: "USER ID", value: "user_id"},
  ];
  const removePass = async (passId)=>{
    setModalContentType("cancelOrder")
    setExpirePassId(passId);
    setShowModal(true);
  }
  const addPass = async ()=>{
    setModalContentType("addPass")
    setShowModal(true);
  }
  const createPass = async ()=>{
    if(numberOfPassesToAdd < 1){
      return toast.error("Number can't be less than 1");
    }
    if(numberOfPassesToAdd > 10){
      return toast.error("Number can't be more than 10");
    }
    setLoader(true)
    let url = URL_LIST.CREATE_CUSTOMER_PASSES;
    axios.post(url, {patientId, role:passRole, count:numberOfPassesToAdd}).then(res=>{
      toast.info(res.data)
    }).catch(err=>{
      toast.error("something went wrong");
    }).finally(()=>{
      setPassRole(null)
      setNumberOfPassesToAdd(null)
      setShowModal(false);
      setLoader(false)
      searchCustomer();
    })
  }

  const expirePass = async () =>{
    setLoader(true)
    const url = `${URL_LIST.EXPIRE_CUSTOMER_PASSES}/${expirePassId}`;
    axios.delete(url).then(res=>{
      toast.info(res.data)
    }).catch(err=>{
      toast.error("Could not expire pass");
    })
    setExpirePassId(null);
    setShowModal(false);
    searchCustomer();
  }
  const expirePassModal = (
    <div>
      <div>Are you sure?</div>
      <Button
        type='submit'
        className="warning"
        style={{ marginTop: "20px", width: "100%"}}
        variant="warning"
        onClick={() => {
          expirePass();
        }}
      >
        Yes
      </Button>
      <Button
        className="regular-button"
        style={{ marginTop: "20px", width: "100%"}}
        onClick={() => {
          setShowModal(false);
        }}
      >
        Close
      </Button>
    </div>
  )

  const addPassModal = (
    <div>
      <div>
        <Dropdown style={{ minWidth: "max-content", width: "300px"}}>
            <Dropdown.Toggle variant="primary" className="regular-button" id="dropdown-basic" style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
              {passRole?passRole:"SELECT ROLE"}
            </Dropdown.Toggle>
            <Dropdown.Menu style={{marginTop: "2px", padding: "0px", width: "100%", minWidth: "max-content"}}>
              {
                roles.map(role=>{
                  return <Dropdown.Item onClick={()=>{setPassRole(role)}} >{role}</Dropdown.Item>
                })
              }
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div style={{justifyContent:"space-around", display:"flex", marginTop:"20px"}} className='add-pass-input'>
          <div>No of passes</div>
        <input style={{width:"40%"}} type={"number"} min={1} max={10} onChange={(e)=>{setNumberOfPassesToAdd(e?.target?.value)}}/>
        </div>
        <div style={{display:"flex", justifyContent:"space-evenly"}}>
          <Button className="regular-button"
            style={{ marginTop: "20px", width: "40%"}}
            disabled = {!(patientId && passRole && numberOfPassesToAdd)}
            onClick={() => {
              createPass();
            }}
          >
            Create
          </Button>
          <Button
        className="regular-button"
        style={{ marginTop: "20px", width: "40%"}}
        onClick={() => {
          setShowModal(false);
        }}
      >
        Close
      </Button>
        </div>
    </div>
  )
  const renderModalContent = () => {
    switch(modalContentType) {
      case "cancelOrder": 
        return expirePassModal;
      case "addPass": 
        return addPassModal;
      default: 
        return <></>;
    }
  }

  const searchCustomer = async () => {
    setLoader(true);
    setCustomerPasses([]);
    let finalSearchValue = searchValue;
    if(searchBy === "order_id") finalSearchValue = getParsedOrderId(searchValue);
    const requestData = {
      search_by: searchBy,
      search_value: finalSearchValue,
    }
    let url = URL_LIST.SEARCH_CUSTOMER_PASSES;
    axios.post(url, requestData)
    .then(res=>{
      if(res?.data?.roles){
        setRoles(res?.data?.roles);
      }
      if(res?.data?.passes) {
        res?.data?.passes?.forEach((pass, idx)=>{
          pass.sno = idx+1
          pass.consumedDate = pass.consumedDate || "NA"
          pass.consultId = pass.consultId || "NA"
          pass.removeBtn =<Button variant="info" disabled={pass.state !=="Active"} onClick={evt=>removePass(pass.id)} size="sm">{pass.state !=="Active"? "EXPIRED":"Expire Pass"}</Button> 
        })
        setCustomerPasses(res?.data?.passes || []);
        setPatientId(res?.data?.patientId)
      }
      else {
        toast.info("No Data Found!");
        setPatientId(null)
      }
      setLoader(false);
    })
    .catch(err=>{
      setPatientId(null)
      setLoader(false);
      toast.error("Unable to search customer details");
    })
  }
  const onSearchClick = () => {
    searchCustomer();
  }
  const columns = [
    { title: "S No", field: "sno" },
    { title: "State", field: "state" },
    { title: "Role", field: "role" },
    { title: "Source", field: "source" },
    { title: "Consumtion Date", field: "consumedDate" },
    { title: "Consultation ID", field: "consultId" },
    { title: "Expiry Date", field: "expiry" },
    { title: "", field: "removeBtn" }
  ]
  return (
    <div className={styles["customer-details"]}>
      <div className="page-heading">Customer Passes</div>
      <div className={styles["main-content"]}>
        <div>Search By</div>

        <div className="radio-group">
          {
            searchParameters.map(sp=>{
              return (
              <div className="radio-input" onClick={evt=>setSearchBy(sp.value)}>
                <input type="radio" className="regular-radio-button" name="searchBy" checked={searchBy===sp.value} />
                <div className={`input-label ${searchBy===sp.value?'active':''}`}>{sp.title}</div>
              </div>
              )
            })
          }
        </div>
        <div className={styles["search-form"]}>
          <input
          value={searchValue}
          onChange={evt=>setSearchValue(evt.target.value)}
          placeholder={searchBy} className="form-control"
          style={{width: "370px"}}
          />
          <Button className="regular-button" style={{marginLeft: "15px"}} disabled={!searchValue} onClick={onSearchClick}>
            Search
          </Button>
          <Button className="regular-button" style={{marginLeft: "15px"}} disabled={!patientId} onClick={addPass}>
            Add Pass
          </Button>
        </div>
        {
          loader?
          <Loader
          type="ThreeDots"
          width={40}
          height={40}
          color="#144166"
          />
          :
          customerPasses.length?
            <div>
              <Table title="SO Passes" columns={columns} rows={customerPasses} search={true} pageSize={customerPasses.length>8?8:customerPasses.length}/>
            </div>
            :
            null
        }
      </div>

      <Modal
        centered
        show={showModal}
        close={true}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {
            loader?
            <Loader type="ThreeDots" width={30} height={30} color="#144166" />
            :
            <div className={styles["info-card"]}>
              {
                renderModalContent()
              }
              <div className={styles["row"]}>
                {
                 
                }
              </div>
            </div>
          }            
        </div>
      </Modal> 
    </div>    
  )
}
export default CustomerSOPasses;