import React, {useState} from 'react';
import styles from './Login.module.css';
import {Button, Form} from 'react-bootstrap';
import axios from 'axios';
import { URL_LIST } from '../../../constants/apiList';
import { store } from '../../../store';
import { useHistory } from "react-router";
import { getBrand } from '../../../helpers/index';
import { getStaticUrl } from '../../../constants/brandConfig';

function Login(props) {
  const history = useHistory();
  const [error,setError] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOTP] = useState("");
  const [otpMsg, setOtpMsg] = useState("OTP Sent");
  const [loader, setLoader] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const brand = getBrand();
  const login = async (evt) => {
    setLoader(true);
    setError("");
    const url = getStaticUrl("VERIFY_OTP")
    await axios.post(url, {password, otp}, {headers:{brand}})
    .then(res=>{
      console.log(res);
      if(res.data.status) {
        const {userInfo, token} = res?.data?.data;
        store.dispatch({type: "USER", payload: userInfo});
        axios.defaults.headers.common["Authorization"] = token;
        localStorage.setItem("token", token);
        history.push("/dashboard");
      }
      else {
        setError(res.data.message);
      }
        setLoader(false);
    })
    .catch(err=>{
      setLoader(false);
      setError(err?.response?.data?.message || "Unable to login");
      console.log("ERROR IN /login", err);
    })
  }
  const verifyPassword = async (evt) => {
    setLoader(true);
    setError("");
    await axios.post(URL_LIST.LOGIN_V2, {password}, {headers:{brand}})
    .then(res=>{
      console.log(res);
      if(res?.data?.status) {
        setOtpMsg(res?.data?.message)
        setShowOTP(true)
      }
      else {
        setError(res.data.message);
      }
      setLoader(false);
    })
    .catch(err=>{
      setLoader(false);
      setError("Unable to login");
      console.log("ERROR IN /login", err);
    })
  }
  const passwordScreen = (
    <div className={styles["login"]}>
      <div className={styles["form-container"]}>
        <div className={styles["title"]}>Login</div>
        <Form.Control className={styles["password-field"]} type="password" placeholder="Pass Key" onChange={evt=>setPassword(evt.target.value)} />
        <div className={styles["error"]}>{error}</div>
        <Button
        className={`regular-button ${styles["login-button"]}`}
        onClick={verifyPassword}
        disabled={loader || !password}
        >
          Send OTP {loader}
        </Button>
      </div>
    </div>
  )
  const otpScreen = (
    <div className={styles["login"]}>
      <div className={styles["form-container"]}>
        <div className={styles["title"]}>Verify OTP</div>
        <div className={styles["otp-msg"]}>
          {otpMsg}
        </div>
        <Form.Control className={styles["password-field"]} type="number" placeholder="OTP" onChange={evt=>setOTP(evt.target.value)} />
        <div className={styles["error"]}>{error}</div>
        <Button
        className={`regular-button ${styles["login-button"]}`}
        onClick={login}
        disabled={loader || !otp || otp.length <4}
        >
          Login {loader}
        </Button>
      </div>
    </div>
  )
  return (
    showOTP? otpScreen: passwordScreen
  ); 
}

export default Login;