import axios from 'axios';
const brandReducer = (state, action) => {
  if (state === undefined) return null;
  switch(action.type) {
    case "SET_BRAND":
      localStorage.setItem("brand",action.payload.brand);
      axios.defaults.headers.common["brand"] = action.payload.brand;
      return action.payload;
    case "REMOVE_BRAND":
      localStorage.setItem("brand", null);
      axios.defaults.headers.common["brand"] = '';
      return null;
    default: 
      return state;
  }
};
export default brandReducer;